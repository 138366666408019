import { Button, List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import { Home } from '@material-ui/icons';
import { mdiFilePdf, mdiFolder } from '@mdi/js';
import Icon from '@mdi/react';
import React, { useState } from 'react';
import Header from '../../components/header';
import Layout from '../../components/layout';
import SEO from '../../components/seo';
import UpdatedInfo from '../../components/templates/UpdatedInfo';
import { escala_gris } from '../../utils/constants';
import { useGlobalStyles } from '../../utils/styles';
import { adquisicionPresupuestoFuente, adquisicionPresupuestoUpdateAt, adquisicionPresupuestos } from '../../utils/transparencia-difusion/constants';
import { IListDocs } from '../../utils/transparencia-difusion/interfaces';
import { useStyles } from '../../utils/transparencia-difusion/styles';

const Planeacion = () => {
  const classes = useStyles();
  const globalClasses = useGlobalStyles();
  const [data, setData] = useState<IListDocs[]>(adquisicionPresupuestos);

  return (
    <Layout>
      <SEO title='Planeacion' />

      <div className={globalClasses.container}>
        <Header title='Programa anual de adquisiciones' />

        <div className={globalClasses.content}>
          <List className={classes.list}>
            {!data[0].root ? (
              <Button
                startIcon={<Home />}
                variant="outlined"
                color="primary"
                onClick={() => setData(adquisicionPresupuestos)}
                style={{ marginBottom: 20 }}>
                Inicio
              </Button>
            ) : null}

            {data.map((document, index) => (
              document.elements ?
                (
                  <ListItem key={index} button onClick={() => setData(document?.elements || [])}>
                    <ListItemAvatar>
                      <Icon path={mdiFolder} size={1.7} color={escala_gris ? '#767576' : '#fad390'} />
                    </ListItemAvatar>
                    <ListItemText primary={document.name} />
                  </ListItem>
                ) : (
                  <a key={index} href={document.file} target="_blank" rel="noopener noreferrer">
                    <ListItem button>
                      <ListItemAvatar>
                        <Icon path={mdiFilePdf} size={1.7} color={escala_gris ? '#767576' : '#e54e5d'} />
                      </ListItemAvatar>
                      <ListItemText primary={document.name} secondary={document.size} />
                    </ListItem>
                  </a>
                )
            ))}
          </List>
        </div>

        <UpdatedInfo fuente={adquisicionPresupuestoFuente} updatedAt={adquisicionPresupuestoUpdateAt} />
      </div>
    </Layout>
  );
};

export default Planeacion;